import React from "react"
// import useInputs from "../hooks/useInputs"

const inputStyle =
  "appearance-none text-md flex-grow m-2 p-2 bg-white bg-opacity-0 placeholder-black "

const ContactForm = () => {
  return (
    <>
      <form
        className="bg-gray-light max-w-lg bg-opacity-75 flex flex-col justify-center mx-auto px-4 pb-4"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
      >
        {/* If this hidden input isn't included, the form won't get to where it needs to go */}
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        {/* ref: https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby || https://stackoverflow.com/questions/61318577/netify-gatsby-form-submission-not-working-with-custom-success-component*/}
        {/* Don't forget this field - won't work without it! */}
        {/* DOCS: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
        <input type="hidden" name="form-name" value="contact" />
        {/* HP */}
        <div className="hidden">
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
          <input name="bot-field" />
        </div>
        {/* End HP */}

        <label
          className="flex items-center border-solid border-b"
          htmlFor="Name"
        >
          Name
          <input
            id="Name"
            name="name"
            placeholder="Enter your name please"
            type="text"
            className={inputStyle}
            required
          ></input>
        </label>

        <label
          className="flex items-center border-solid border-b"
          htmlFor="Email"
        >
          Email
          <input
            id="Email"
            name="email"
            placeholder="you@youremail.com"
            type="email"
            className={inputStyle}
            required
          ></input>
        </label>

        <label
          className="flex flex-col text-left pt-4 border-solid border-b"
          htmlFor="Message"
        >
          Message
          <textarea
            id="Message"
            name="message"
            placeholder="Please provide details"
            className="appearance-none text-md flex-grow mt-2 w-full bg-white bg-opacity-0 placeholder-black "
            required
          ></textarea>
        </label>

        <button
          type="submit"
          color="red"
          className="mt-2 py-2 font-heading bg-red-light text-white"
        >
          Send
        </button>
      </form>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </>
  )
}
export default ContactForm
